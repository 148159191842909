$(document).ready(function(){
    // men click
    $('#menu-item-11').click(function(e) {
        $( ".sub-category" ).hide();
        $( "#menu-id-01" ).slideDown();
        $( "#menu-id-02" ).hide();
        $( "#menu-id-03" ).hide();
        $( "#menu-id-04" ).hide();
        $( "#menu-id-05" ).hide();
        overlayon();
    });

    // woman click
    $('#menu-item-12').click(function(e) {
        $( ".sub-category" ).hide();
        $( "#menu-id-01" ).hide();
        $( "#menu-id-02" ).slideDown();
        $( "#menu-id-03" ).hide();
        $( "#menu-id-04" ).hide();
        $( "#menu-id-05" ).hide();
        overlayon();
    });

    // kids click
    $('#menu-item-13').click(function(e) {
        $( ".sub-category" ).hide();
        $( "#menu-id-01" ).hide();
        $( "#menu-id-02" ).hide();
        $( "#menu-id-03" ).slideDown();
        $( "#menu-id-04" ).hide();
        $( "#menu-id-05" ).hide();
        overlayon();
    });

    // sale click
    $('#menu-item-14').click(function(e) {
        $( ".sub-category" ).hide();
        $( "#menu-id-01" ).hide();
        $( "#menu-id-02" ).hide();
        $( "#menu-id-03" ).hide();
        $( "#menu-id-04" ).slideDown();
        $( "#menu-id-05" ).hide();
        overlayon();
    });


    //meshkuj
    $('#brand-20-1').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".brand-20-1" ).slideToggle();
        $(this).addClass('active');
    });
    $('#brand-20-2').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".brand-20-2" ).slideToggle();
        $(this).addClass('active');
    });
    $('#brand-20-3').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".brand-20-3" ).slideToggle();
        $(this).addClass('active');
    });


    //femra
    $('#brand-21-1').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".brand-21-1" ).slideToggle();
        $(this).addClass('active');
    });
    $('#brand-21-2').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".brand-21-2" ).slideToggle();
        $(this).addClass('active');
    });
    $('#brand-21-3').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".brand-21-3" ).slideToggle();
        $(this).addClass('active');
    });


    //femije
    $('#femije-1').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".femije-1" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#femije-2').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".femije-2" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#femije-3').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".femije-3" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#femije-4').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".femije-4" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#femije-5').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".femije-5" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#femije-6').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".femije-6" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#femije-7').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".femije-7" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#femije-8').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".femije-8" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#femije-9').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".femije-9" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });


    //femije
    $('#zbritje-1').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".zbritje-1" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#zbritje-2').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".zbritje-2" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#zbritje-3').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".zbritje-3" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#zbritje-4').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".zbritje-4" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#zbritje-5').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".zbritje-5" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#zbritje-6').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".zbritje-6" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#zbritje-7').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".zbritje-7" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#zbritje-8').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".zbritje-8" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });
    $('#zbritje-9').click(function(e) {
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
        $( ".sub-category" ).hide();
        $( ".zbritje-9" ).slideToggle();
        $(this).addClass('active');
        $(this).parent().parent().parent().addClass('active');
    });

    $(".menu-item").click(function(){
        $( ".sub-menu-box" ).removeClass('active');
        $( ".child" ).removeClass('active');
    });




//CLOSE
    $('.close-submenu').click(function(e) {
        $( "#menu-id-01" ).hide();
        $( "#menu-id-02" ).hide();
        $( "#menu-id-03" ).hide();
        $( "#menu-id-04" ).hide();
        $( "#menu-id-05" ).show();
        overlayoff();
    });
});








