function overlayon() {
    document.getElementById("overlay").style.display = "block";
}

function overlayoff() {
    document.getElementById("overlay").style.display = "none";
    $( "#menu-id-01" ).slideUp();
    $( "#menu-id-02" ).slideUp();
    $( "#menu-id-03" ).slideUp();
    $( "#menu-id-04" ).slideUp();
    $( "#menu-id-05" ).slideUp();
}

//woocomerce single product
function overlayGalleryOn() {
    document.getElementById("overlay-gallery").style.display = "block";
    $('.page-slider-gallery').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 86000,
        customPaging : function(slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<a>'+(i+1)+'</a>';
        },
    });
}

function overlayGalleryOff() {
    document.getElementById("overlay-gallery").style.display = "none";
}
//END