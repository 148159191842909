(function ($, app, _) {
    "use strict";

    app.mobileMenu = function () {

        $(".mobile_menu").slideMobileMenu({
            onMenuLoad: function(menu) {
                //   console.log(menu)
                // console.log("menu loaded")
            },
            onMenuToggle: function(menu, opened) {
                if(opened){
                    $('.logo-white').hide();
                    $('.logo-small-white').show();
                    $('#sm_menu_ham').css({'right': 'auto',
                        'padding-left': '45%'});

                    $('#header-cart-mob').hide();
                    $('#header-close-menu-mob').css('display','inline-block');
                }
                else {
                    $('.logo-white').show();
                    $('.logo-small-white').hide();
                    $('#sm_menu_ham').css({'right': '60px',
                        'padding-left': 'auto'});

                    $('#header-cart-mob').css('display','inline-block');
                    $('#header-close-menu-mob').hide();
                }
            }
        });
};

})(jQuery, app, _);

$('#header-close-menu-mob').click(function(e) {
    $("#sm_menu_ham").click();
});
$('#header-close-menu-button').click(function(e) {
    $("#sm_menu_ham").click();
});