/* activate tab on anchor link */

(function ($, app) {
    "use strict";

    app.setTabActiveAnchor = function (event) {
        //activate
        if ($('.m17').length > 0) {

                var target = '';
                var url = document.location.toString();
                if (url.match('#')) {
                    target = url.split('#')[1];
                }
                if ((target == 'tab-1')) {
                    activaTab('m17tab-1');

                }
                if ((target == 'tab-2')) {
                    activaTab('m17tab-2');
                }

                if ((target == 'tab-3')) {
                    activaTab('m17tab-3');
                }
                if ((target == 'tab-4')) {
                    activaTab('m17tab-4');
                }

                if ((target == 'tab-5')) {
                    activaTab('m17tab-5');
                }
        }

        function activaTab(tab) {
            $('#pills-tab a[href="#' + tab + '"]').tab('show');
            $('html,body').animate({
                    scrollTop: $("#myTabContent").offset().top - 280
                },
                'fast');
        };
    }

})(jQuery, app);