(function ($, app, _) {
    "use strict";

    app.SlickSliderSetup = function () {
        $('.home-slider').slick({
            dots: false,
            infinite: true,
            fade: true,
            speed: 300,
            autoplaySpeed: 6000,
            asNavFor: '.home-slider-footer',
            autoplay: true,
        });

        $('.home-slider-new').slick({
            dots: true,
            infinite: true,
            autoplay: true,
            prevArrow: false,
            nextArrow: false,
            autoplaySpeed: 5000,
            dotsClass: 'slider-paging-number',
            slidesToShow: 1,
            slidesToScroll: 1
        });

        $('.home-slider-footer').slick({
            dots: false,
            infinite: true,
            fade: true,
            prevArrow: null,
            nextArrow: null
        });



        $('.page-slider').slick({
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 86000,
            customPaging : function(slider, i) {
                var thumb = $(slider.$slides[i]).data();
                return '<a>'+(i+1)+'</a>';
            },
        });
/*
        $('.home-slider-home').slick({
            dots: true,
            infinite: true,
            autoplay: true,
            prevArrow: false,
            nextArrow: false,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1
        });

 */

    };

})(jQuery, app, _);