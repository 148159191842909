/* Kunstler Slider */

(function ($, app, _) {
    "use strict";

    app.ArtistSlider = function () {
        if ($('.artist-slider-content').length > 0) {


            //gjeje numrin maksimal te slajdave
            var total_slides = $(".artist-slider-content").children().length;
            total_slides = total_slides - 1; // zvogeloje per 1 me pershtat me numrin e ID te carousel


            var sliderid = $('.carousel-item.active').attr("id");
            var prev_slideid = parseInt(sliderid) - 1 ;
            var next_slideid = parseInt(sliderid) + 1 ;
            if(prev_slideid < 0) {
                prev_slideid = total_slides;
            }
            if(next_slideid > total_slides) {
                next_slideid = 0;
            }
            $('#btn-prev').html($('#slider-title-' + prev_slideid ).html());
            $('#btn-next').html($('#slider-title-' + next_slideid ).html());


            // ne ne rast se kemi ndrrim te slidave

            $('#artistsslider').on('slid.bs.carousel', function () {

                var sliderid = $('.carousel-item.active').attr("id");

                var prev_slideid = parseInt(sliderid) - 1 ;
                var next_slideid = parseInt(sliderid) + 1 ;


                if(prev_slideid < 0) {
                    prev_slideid = total_slides;
                }

                if(next_slideid > total_slides) {
                    next_slideid = 0;
                }

                $('#btn-prev').html($('#slider-title-' + prev_slideid ).html());

                $('#btn-next').html($('#slider-title-' + next_slideid ).html());

            })

        };
    }
})(jQuery, app, _);