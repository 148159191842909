/* options module */

(function ($, app, _) {
    "use strict";

    app.ProductSliderList = function () {
        if ($('.product-slider-content').length > 0) {



            //jepja lartesine
            jQuery("#site").css({"height": y + "px"});

            //gjeje numrin maksimal te slajdave
            var total_slides = $(".product-slider-content").children().length;
            total_slides = total_slides - 1; // zvogeloje per 1 me pershtat me numrin e ID te carousel


            var sliderid = $('.carousel-item.active').attr("id");
            var prev_slideid = parseInt(sliderid) - 1 ;
            var next_slideid = parseInt(sliderid) + 1 ;
            if(prev_slideid < 0) {
                prev_slideid = total_slides;
            }
            if(next_slideid > total_slides) {
                next_slideid = 0;
            }
            $('#btn-prev').html($('#slider-title-' + prev_slideid ).html());
            $('#btn-next').html($('#slider-title-' + next_slideid ).html());


            // ne ne rast se kemi ndrrim te slidave

            $('#productsslider').on('slid.bs.carousel', function () {

                var sliderid = $('.carousel-item.active').attr("id");

                var prev_slideid = parseInt(sliderid) - 1 ;
                var next_slideid = parseInt(sliderid) + 1 ;


                if(prev_slideid < 0) {
                    prev_slideid = total_slides;
                }

                if(next_slideid > total_slides) {
                    next_slideid = 0;
                }

                $('#btn-prev').html($('#slider-title-' + prev_slideid ).html());

                $('#btn-next').html($('#slider-title-' + next_slideid ).html());

            })

            if (app.isDesktop()) {
                //gjeje lartesine maksimale te browserit aktualisht
                var w = window,
                    d = document,
                    e = d.documentElement,
                    g = d.getElementsByTagName('body')[0],
                    y = w.innerHeight || e.clientHeight || g.clientHeight;
                y = y - 83;
            }

        };
    }
})(jQuery, app, _);