/**
 * add swipe to slider
 */

(function ($, app) {
    "use strict";


    $("#single-product-slider").on("touchstart", function(event){
        var xClick = event.originalEvent.touches[0].pageY;
        $(this).one("touchmove", function(event){
            var xMove = event.originalEvent.touches[0].pageY;
            if( Math.floor(xClick - xMove) > 5 ){
                $("#single-product-slider").carousel('next');
            }
            else if( Math.floor(xClick - xMove) < -5 ){
                $("#single-product-slider").carousel('prev');
            }
        });
        $("#single-product-slider").on("touchend", function(){
            $(this).off("touchmove");
        });
    });

    // scroll slides on mouse scroll
    $("#single-product-slider").bind('mousewheel DOMMouseScroll', function(e){

        if(e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
            $(this).carousel('prev');

        }
        else{
            $(this).carousel('next');

        }
    });



})(jQuery, app);

