/* options module */

(function ($, app, _) {
	"use strict";

	app.AutoHeight = function () {
        if ($('.artist-slider-content').length > 0) {

            //gjeje lartesine maksimale te browserit aktualisht
            var w = window,
                d = document,
                e = d.documentElement,
                g = d.getElementsByTagName('body')[0],
                y = w.innerHeight || e.clientHeight || g.clientHeight;
            y = y - 83;

            //jepja lartesine
           // jQuery("#site").css({"height": y + "px"});

        };
    }
})(jQuery, app, _);