
(function ($, app, _) {
    "use strict";

    app.CollapseExpand = function () {

        $("#show-filters").click(function () {
            var $this = $(this);
            if ( $this.hasClass( "active" ) ) {
                $this.find('.largo').hide();
                $this.find('.shfaq').show();
                $this.removeClass('active');
            }
            else {
                $this.addClass('active');
                $this.find('.largo').show();
                $this.find('.shfaq').hide();
            }
           // $this.text('Mbyll');
            $(".woof").slideToggle("slow");

        });
    };

})(jQuery, app, _);