/* main file */

(function ($, app) {

  // Immediately (before anything goes)
  (function () {
  })();

  // When DOM is ready
    $(window).on('ready', function() {
  });

  // When everything is rendered
    $(window).on('load', function() {
        app.mobileMenu();
        app.AutoHeight();
        app.setDimensions();
        app.AddClassScroll();
        app.SlickSliderSetup();
        app.CollapseExpand();
    });

})(jQuery, app);

$(document).ready(function() {
    //$('select').niceSelect();
});
